<template>
  <div class="dashboard">
    <h3 class="title">{{ company }}</h3>

    <div class="card-container">
      <!-- Valuation Card -->
      <v-card class="card" elevation="4">
        <v-card-title class="card-title">Valuation</v-card-title>
        <v-card-subtitle class="card-subtitle">En millions*</v-card-subtitle>
        <v-card-text class="card-content">
          <div class="table-wrapper">
            <table class="styled-table">
              <thead>
                <tr>
                  <th></th>
                  <th v-for="year in years" :key="year">{{ year }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, key) in valuationData" :key="key" @click="showBarGraph(key, valuationData)">
                  <td>{{ key }}</td>
                  <td v-for="year in years" :key="year">{{ data[year] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card-text>
      </v-card>

      <!-- Income Statement Card -->
      <v-card class="card" elevation="4">
        <v-card-title class="card-title">Income Statement</v-card-title>
        <v-card-subtitle class="card-subtitle">En millions*</v-card-subtitle>
        <v-card-text class="card-content">
          <div class="table-wrapper">
            <table class="styled-table">
              <thead>
                <tr>
                  <th></th>
                  <th v-for="year in years" :key="year">{{ year }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, key) in incomeStatementData" :key="key" @click="showBarGraph(key, incomeStatementData)">
                  <td>{{ key }}</td>
                  <td v-for="year in years" :key="year">{{ value[year] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card-text>
      </v-card>
    </div>

    <!-- Dialog for Bar Graph -->
    <v-dialog v-model="dialogVisible" max-width="80%">
      <v-card>
        <v-card-title class="dialog-bar-chart-title">Bar Graph</v-card-title>
        <v-card-text>
          <div class="bar-chart-container">
            <canvas ref="barChart"></canvas>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn class="dialog-close-btn" @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<style scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #ffffff;
  min-height: 100vh;
}

.title {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 700;
  color: #1a202c; /* Updated color for better readability */
}

.card-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 1200px;
}

.card {
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
}

.card-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2d3748; /* Darker color for better contrast */
}

.card-subtitle {
  font-size: 0.875rem;
  color: #4a5568; /* Subtle gray color */
}

.card-content {
  padding: 1rem;
}

.table-wrapper {
  overflow-x: auto;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;
  color: #2d3748; /* Darker text color for readability */
}

.styled-table th, .styled-table td {
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid #e2e8f0; /* Light gray border */
}

.styled-table th {
  background-color: #edf2f7; /* Light gray background for headers */
  font-weight: 600;
}

.styled-table tbody tr:nth-child(even) {
  background-color: #f7fafc; /* Slightly different gray for rows */
}

.styled-table tbody tr:hover {
  background-color: #e2e8f0; /* Light gray for hover effect */
}

.dialog-title {
  font-size: 1.5rem;
  font-weight: 600;
}


.bar-chart-container {
  position: relative;
  width: 100%;
  height: 500px; /* Default height for desktop */
}

@media (max-width: 600px) {
  .bar-chart-container {
    height: 300px; /* Adjusted height for mobile */
  }
}

.dialog-bar-chart-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #004d40; /* Dark teal */
}

.dialog-close-btn {
  background-color: #004d40; /* Button color to match the theme */
  color: white;
}

.dialog-close-btn:hover {
  background-color: #48ab95; /* Slightly lighter green on hover */
}

</style>



  
  <script>
import Chart from 'chart.js/auto';
export default {
  name: 'MyData',
  props: {
    company: String,
    data: Object,
    },


  data() {
    return {
      dialogVisible: false,
      selectedData: null,
      years: [],
      dialogVisibilityChanged: false,
      valuationData: {},
      incomeStatementData: {}
    };
  },
  methods: {
    showBarGraph(key, data) {
      this.selectedData = data[key];
      this.dialogVisible = true;
      this.dialogVisibilityChanged = true;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.dialogVisibilityChanged = false; 
    },
    renderChart() {
  if (!this.selectedData) return;

  const ctx = this.$refs.barChart.getContext('2d');
  
  // Destroy previous chart instance if it exists
  if (this.barChart) {
    this.barChart.destroy();
  }

  const labels = Object.keys(this.selectedData);
  const values = Object.values(this.selectedData);

  const isMobile = window.innerWidth <= 600; // Detect if mobile device
  const barThickness = isMobile ? 12 : 25; // Bar thickness for mobile vs desktop
  const fontSize = isMobile ? 10 : 14; // Adjust font size for mobile vs desktop

  // Generate the bar colors based on conditions
  const backgroundColors = values.map((value, index) => {
    if (index === 0) {
      return '#48ab95'; // First bar should always be green
    }
    // Compare current value with the previous one
    return value > values[index - 1] ? '#48ab95' : '#F08080'; // Green if greater, Coral if lesser
  });

  const borderColors = backgroundColors.map(color => {
    return color; // Border color is the same as the background color
  });

  this.barChart = new Chart(ctx, {
    type: 'bar',
    data: {
      labels: labels,
      datasets: [{
        label: 'Value',
        data: values,
        backgroundColor: backgroundColors, // Use dynamic background colors
        borderColor: borderColors, // Use dynamic border colors
        borderWidth: 2,
        barThickness: barThickness // Dynamically adjust bar thickness
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 1000,
        easing: 'easeInOutQuart' // Smooth and modern animation
      },
      plugins: {
        legend: {
          display: true,
          position: 'top',
          labels: {
            color: '#004d40',  // Dark teal for legend text
            font: {
              size: fontSize
            }
          }
        },
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat().format(context.parsed.y); // Format numbers with commas
              }
              return label;
            }
          },
          backgroundColor: '#004d40',
          titleColor: '#fff',
          bodyColor: '#fff',
          borderColor: '#BBDEFB',
          borderWidth: 1
        }
      },
      scales: {
        x: {
          grid: {
            display: false // Hide grid lines for a cleaner look
          },
          ticks: {
            color: '#004d40', // Dark teal for axis labels
            font: {
              size: fontSize
            }
          }
        },
        y: {
          grid: {
            borderDash: [4, 4], // Dotted grid lines
            color: '#BBDEFB', // Light blue grid lines
          },
          ticks: {
            beginAtZero: true,
            color: '#004d40', // Dark teal for axis labels
            font: {
              size: fontSize
            }
          }
        }
      },
      layout: {
        padding: {
          left: isMobile ? 10 : 20,
          right: isMobile ? 10 : 20,
          top: 20,
          bottom: 20
        }
      }
    }
  });
},
    async fetchIncomeStatementData() {
  try {
    const url = `https://api.foliosentry.com/api/income_statement?company=${this.company}`;
    const response = await fetch(url);
    const text = await response.text();

    // Replace all instances of `NaN` with `null`
    const safeText = text.replace(/NaN/g, 'null');

    // Parse the modified text as JSON
    const apiData = JSON.parse(safeText);

    this.parseAPIData(apiData, false);
  } catch (error) {
    console.error('Error fetching or parsing data:', error);
  }
},
async fetchValuationData() {
  try {
    const url = `https://api.foliosentry.com/api/valorization?company=${this.company}`;
    const response = await fetch(url);
    const text = await response.text();

    // Replace all instances of `NaN` with `null`
    const safeText = text.replace(/NaN/g, 'null');

    // Parse the modified text as JSON
    const apiData = JSON.parse(safeText);

    this.parseAPIData(apiData, true);
  } catch (error) {
    console.error('Error fetching or parsing data:', error);
  }
},

    parseAPIData(apiData, valueData) {
      if (!apiData || Object.keys(apiData).length === 0) {
        console.error("apiData is null or undefined");
        return;
      }
        const firstEntry = apiData[Object.keys(apiData)[0]];
        const keys = Object.keys(firstEntry);
        keys.forEach(key => {
            valueData ? this.valuationData[key] = {} : this.incomeStatementData[key] = {};
        });

        Object.entries(apiData).forEach(([timestamp, data]) => {
            const date = new Date(timestamp);
            const year = date.getFullYear();

            if (!this.years.includes(year)) {
                this.years.push(year);
            }

            keys.forEach(key => {
                
                valueData ?     this.valuationData[key][year] = data[key] !== undefined ? data[key] : null : this.incomeStatementData[key][year] = data[key] !== undefined ? data[key] : null;

            });
        });

        // Sort the years array
        this.years.sort();
      },

  },
  watch: {
    dialogVisible() {
            this.$nextTick(() => {
                if (this.$refs.barChart) {
                    this.renderChart();
                } else {
                    console.error("Canvas element for radar chart is not available.");
                }
            });
    }
},
  created() {
    const currentYear = new Date().getFullYear();
    for (let i = currentYear -1; i > currentYear - 4; i--) {
      this.years.push(i);
    }
  },
    mounted() {
    this.fetchIncomeStatementData();
    this.fetchValuationData();
  }
};
</script>
